import lightGallery from 'lightgallery';

import lgVideo from 'lightgallery/plugins/video';
import { Modal } from 'bootstrap/js/dist/modal';
import { Offcanvas } from 'bootstrap/js/dist/offcanvas';

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, Autoplay]);

// Инициализация при загрузке DOM-дерева
document.addEventListener('DOMContentLoaded', () => {
  initLightGalleries();
  initSliderBox();
  initSliderFeedbacks();
  window.addEventListener('scroll', onScroll);
  onScroll();
});

// Функция инициализации галерей изображений
function initLightGalleries() {
  const galleryElements = document.querySelectorAll('[data-gallery]');
  galleryElements.forEach((el) => {
    lightGallery(el, {
      licenseKey: '0000 0000 0000 0000',
      download: false,
      plugins: [lgVideo],
      selector: '[data-src]',
    });
  });
}

function initSliderBox() {
  const sliderBox = new Swiper('.slider-box', {
    loop: true,
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 400,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}
function initSliderFeedbacks() {
  const sliderFeedbacks = new Swiper('.slider-feedbacks', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 400,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}

// Определяем функцию для отслеживания проскролливания
function onScroll() {
  // Получаем объект событий прокрутки
  const bodyY = document.documentElement.scrollTop;
  // Вычисляем общее количество экранов (уровней) проскроллившейся высоты
  let scrollLevel = document.documentElement.scrollTop / window.innerHeight;

  const btnScrollTop = document.querySelector('.btn-scroll-top');
  const headerEl = document.querySelector('.header');

  // Если мы на уровне или выше, считаем что мы просматрили более 2 экранов
  if (scrollLevel >= 1) {
    btnScrollTop ? btnScrollTop.classList.add('visible') : false;
  } else {
    btnScrollTop ? btnScrollTop.classList.remove('visible') : false;
  }

  if (bodyY !== 0) {
    headerEl ? headerEl.classList.add('scrolled') : false;
  } else {
    headerEl ? headerEl.classList.remove('scrolled') : false;
  }
}

// Экспорт модального окна для доступа извне
window.modal = Modal;
window.offcanvas = Offcanvas;
